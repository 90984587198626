import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import Logo from "../Logo/Logo"
import { Grid, List, Popover } from "@material-ui/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import smoothscroll from "smoothscroll-polyfill"
import SocialMedia from "../SocialMedia/SocialMedia"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import { useIntl } from "gatsby-plugin-intl"

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.colors.white};
`
const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
`

const Menu = styled.nav`
  display: flex;
  list-style: none;
`

const MenuItem = styled.li`
  margin: ${({ mobile }) => (mobile ? " 5px 0 " : "0 10px")};
  display: ${({ mobile }) => (mobile ? " flex" : "block")};
  justify-content: ${({ mobile }) => (mobile ? " center" : "block")};
`

const StyledMenu = styled(Menu)`
  flex-direction: column;
  li {
    margin: 10px 0;
    a {
      text-decoration: none;
      padding: 5px 10px;
      transition: color 0.15s linear;
      &:hover {
        color: gray;
      }
    }
  }
`
const Link = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  ::after {
    position: absolute;
    left: 0;
    bottom: 5px;
    content: "";
    width: 100%;
    height: 1px;
    background-color: black;
    transform: scaleX(0);
    transition: transform 0.15s linear;
  }

  &:hover ::after {
    transform: scaleX(1);
  }
`

const StyledAniLink = styled(AniLink)`
  position: relative;
  display: block;
  cursor: pointer;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;

  ::after {
    position: absolute;
    left: 0;
    bottom: 5px;
    content: "";
    width: 100%;
    height: 1px;
    background-color: black;
    transform: scaleX(0);
    transition: transform 0.15s linear;
  }

  &:hover ::after {
    transform: scaleX(1);
  }

  &.active ::after {
    transform: scaleX(1);
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.colors.grayBlue};
    width: 100%;
  }
`

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`
const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    padding: 20px;
  }
`

const StyledMobileAniLink = styled(AniLink)`
  padding: 10px 0;
`
const Navigation = () => {
  const [isMenuOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { formatMessage, locale } = useIntl()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setIsOpen(open)
  }

  const navRef = useRef()

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }, [])

  const handleNavigation = e => {
    e.preventDefault()
    const sectionName = e.target.id
    const navHeight = navRef.current.getBoundingClientRect().height
    const bodyRect = document.body.getBoundingClientRect().top
    smoothscroll.polyfill()

    const isHomePage =
      window.location.pathname === "/en/" || window.location.pathname === "/pl/"

    switch (sectionName) {
      case "home":
        if (isHomePage) {
          window.scrollTo({ top: 0, behavior: "smooth" })
          if (window.innerWidth < 1024) {
            // setIsOpen(false)
          } else return
        } else return

        break
      case "about":
        if (!isHomePage) {
          setTimeout(() => {
            const sectionAbout = document.querySelector(`.about-us-description`)
            const positionAbout = sectionAbout.getBoundingClientRect().top
            const elementAboutPosition = positionAbout - bodyRect - navHeight
            window.scrollTo({ top: 0 })
            window.scrollTo({ top: elementAboutPosition })
            if (window.innerWidth < 1024) {
              setIsOpen(false)
            } else return
          }, 1500)
        } else {
          const sectionAbout = document.querySelector(`.about-us-description`)
          const positionAbout = sectionAbout.getBoundingClientRect().top
          const elementAboutPosition = positionAbout - bodyRect - navHeight
          window.scrollTo({ top: elementAboutPosition, behavior: "smooth" })
          if (window.innerWidth < 1024) {
            setIsOpen(false)
          } else return
        }
        break
      case "contact":
        if (!isHomePage) {
          setTimeout(() => {
            const sectionContact = document.querySelector(
              `section.${sectionName}`
            )
            const positionContact = sectionContact.getBoundingClientRect().top
            const elementContactPosition =
              positionContact - bodyRect - navHeight
            window.scrollTo({ top: elementContactPosition, behavior: "smooth" })
            if (window.innerWidth < 1024) {
              // setIsOpenMenu(false)
            } else return
          }, 1500)
        } else {
          const sectionContact = document.querySelector(
            `section.${sectionName}`
          )
          const positionContact = sectionContact.getBoundingClientRect().top
          const elementContactPosition = positionContact - bodyRect - navHeight
          window.scrollTo({ top: elementContactPosition, behavior: "smooth" })
          if (window.innerWidth < 1024) {
            // setIsOpenMenu(false)
          } else return
        }
        break
      default:
        break
    }
  }
  return (
    <StyledAppBar ref={navRef}>
      <StyledToolbar>
        <AniLink
          paintDrip
          duration={1}
          hex="#252a36"
          to={`/${locale}/`}
          onClick={handleNavigation}
          id="home"
        >
          <Logo />
        </AniLink>

        {isMobile === null ? null : (
          <>
            {isMobile ? (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <StyledDrawer
                  anchor="right"
                  open={isMenuOpen}
                  onClose={toggleDrawer(false)}
                  component="div"
                >
                  <DrawerHeader>
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseIcon />
                    </IconButton>
                  </DrawerHeader>

                  <Divider light={true} />

                  <StyledMobileAniLink
                    paintDrip
                    duration={1}
                    hex="#252a36"
                    to={`/${locale}/`}
                    onClick={toggleDrawer(false)}
                    style={{ margin: "0 auto" }}
                  >
                    <Logo />
                  </StyledMobileAniLink>
                  <Divider light={true} />

                  <List>
                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/where-we-fly`}
                        onClick={toggleDrawer(false)}
                      >
                        <strong>
                          {formatMessage({ id: "Where do we fly" })}{" "}
                          <span role="img" aria-label="balloon">
                            &#x1F388;
                          </span>
                        </strong>
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />

                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/offer/flights`}
                        onClick={toggleDrawer(false)}
                      >
                        {formatMessage({ id: "Touristic flights" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />
                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/offer/banners`}
                        onClick={toggleDrawer(false)}
                      >
                        {formatMessage({ id: "Advertising banners" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />
                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/offer/skydiving`}
                        onClick={toggleDrawer(false)}
                      >
                        {formatMessage({ id: "Skydiving" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />
                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/offer/selling`}
                        onClick={toggleDrawer(false)}
                      >
                        {formatMessage({ id: "Balloon sales" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />

                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/`}
                        onClick={handleNavigation}
                        id="about"
                      >
                        {formatMessage({ id: "About us" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />

                    <MenuItem mobile>
                      <StyledMobileAniLink
                        paintDrip
                        duration={1}
                        hex="#252a36"
                        to={`/${locale}/contact`}
                        id="contact"
                      >
                        {formatMessage({ id: "Contact" })}
                      </StyledMobileAniLink>
                    </MenuItem>
                    <Divider light={true} />
                    <SocialMedia vertical="row" />
                    <Divider light={true} />
                    <Grid item container justify="center">
                      <LanguageSwitcher />
                    </Grid>
                  </List>
                </StyledDrawer>
              </>
            ) : (
              <Menu>
                <MenuItem>
                  <StyledAniLink
                    paintDrip
                    duration={1}
                    hex="#252a36"
                    to={`/${locale}/where-we-fly`}
                    activeClassName="active"
                  >
                    <strong>
                      {formatMessage({ id: "Where do we fly" })}{" "}
                      <span role="img" aria-label="balloon">
                        &#x1F388;
                      </span>
                    </strong>
                  </StyledAniLink>
                </MenuItem>
                <MenuItem>
                  <Link
                    aria-describedby={id}
                    onClick={handleClick}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {formatMessage({ id: "Offer" })} <ArrowDropDownIcon />
                  </Link>
                  <StyledPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <StyledMenu>
                      <MenuItem>
                        <AniLink
                          paintDrip
                          duration={1}
                          hex="#252a36"
                          to={`/${locale}/offer/flights`}
                          onClick={handleClose}
                        >
                          {formatMessage({ id: "Touristic flights" })}
                        </AniLink>
                      </MenuItem>
                      <MenuItem>
                        <AniLink
                          paintDrip
                          duration={1}
                          hex="#252a36"
                          to={`/${locale}/offer/banners`}
                          onClick={handleClose}
                        >
                          {formatMessage({ id: "Advertising banners" })}
                        </AniLink>
                      </MenuItem>
                      <MenuItem>
                        <AniLink
                          paintDrip
                          duration={1}
                          hex="#252a36"
                          to={`/${locale}/offer/skydiving`}
                          onClick={handleClose}
                        >
                          {formatMessage({ id: "Skydiving" })}
                        </AniLink>
                      </MenuItem>
                      <MenuItem>
                        <AniLink
                          paintDrip
                          duration={1}
                          hex="#252a36"
                          to={`/${locale}/offer/selling`}
                          onClick={handleClose}
                        >
                          {formatMessage({ id: "Balloon sales" })}
                        </AniLink>
                      </MenuItem>
                    </StyledMenu>
                  </StyledPopover>
                </MenuItem>

                <MenuItem>
                  <StyledAniLink
                    paintDrip
                    duration={1}
                    hex="#252a36"
                    to={`/${locale}/`}
                    onClick={handleNavigation}
                    id="about"
                  >
                    {formatMessage({ id: "About us" })}
                  </StyledAniLink>
                </MenuItem>
                <MenuItem>
                  <StyledAniLink
                    paintDrip
                    duration={1}
                    hex="#252a36"
                    to={`/${locale}/contact`}
                  >
                    {formatMessage({ id: "Contact" })}
                  </StyledAniLink>
                </MenuItem>
                <MenuItem>
                  <LanguageSwitcher />
                </MenuItem>
              </Menu>
            )}
          </>
        )}
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Navigation
