import styled from "styled-components"
import { Button } from "@material-ui/core"

export const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 0px 10px 0px #92b2ea;
  visibility: hidden;
  transition: box-shadow 0.15s linear;
  width: 185px;
  height: 50px;
  svg {
    path {
      color: white;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: 0px 0px 0px 0px #271c84;
  }
  .MuiButton-label {
    color: white;
  }
`
