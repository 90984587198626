import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const StyledImage = styled(Image)`
  pointer-events: none;
`

const Logo = props => {
  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "logo-color" }) {
        childImageSharp {
          fixed(height: 50, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledImage fixed={data.logo.childImageSharp.fixed} style={props.style} />
  )
}

export default Logo
