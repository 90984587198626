import React, { useEffect } from "react"
import FacebookIcon from "@material-ui/icons/Facebook"
import YouTubeIcon from "@material-ui/icons/YouTube"
import InstagramIcon from "@material-ui/icons/Instagram"
import styled from "styled-components"

const Aside = styled.aside`
  position: ${({ vertical }) => (vertical === "row" ? "static" : "absolute")};
  top: 50%;
  right: 30px;
  transform: ${({ vertical }) =>
    vertical === "row" ? "" : "translateY(-50%)"};
  display: ${({ vertical }) => (vertical === "row" ? "flex" : "none")};
  opacity: ${({ vertical }) => (vertical === "row" ? "1" : "0")};
  flex-direction: ${({ vertical }) => (vertical === "row" ? "row" : "column")};
  justify-content: ${({ component }) =>
    component === "footer" ? "flex-start" : "center"};
  align-items: center;
  margin: 0 auto;
  transition: opacity 0.15s linear;
  a {
    margin: ${({ vertical }) => (vertical === "row" ? "10px" : "3px 0")};
    margin: ${({ component }) => component === "footer" && "5px"};
    padding: 5px;
    transform: scale(0.9);
    transition: transform 0.15s linear;
    &:nth-child(1) {
      margin-left: ${({ component }) => component === "footer" && "0"};
      padding-left: ${({ component }) =>
        component === "footer" ? "0" : "5px"};
    }
    &:hover {
      transform: scale(1);
    }
  }
  a:nth-child(1) {
    svg {
      font-size: 30px;
      color: #3578e5 !important;

      path {
        color: #3578e5 !important;
      }
    }
  }
  a:nth-child(2) {
    svg {
      font-size: 30px;
      color: #ff0000 !important;

      path {
        color: #ff0000 !important;
      }
    }
  }
  a:nth-child(3) {
    svg {
      color: #fff !important;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background: #d6249f;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      path {
        background-color: #fff !important;
        color: #fff !important;
      }
    }
  }
`

const A = styled.a`
  color: white;
  text-decoration: none;
`

const SocialMedia = props => {
  const { vertical, component } = props
  useEffect(() => {
    const aside = document.querySelector(".aside-social-media")

    setTimeout(() => {
      aside.style.display = "flex"
    }, 1500)
    setTimeout(() => {
      aside.style.opacity = "1"
    }, 1800)
  })
  return (
    <Aside
      vertical={vertical}
      component={component}
      className="aside-social-media"
    >
      <A href="https://www.facebook.com/skywalkersbc">
        <FacebookIcon />
      </A>
      <A href="https://www.youtube.com/channel/UC62FEhJkX0YbtRaPme76sAQ">
        <YouTubeIcon />
      </A>
      <A href="https://www.instagram.com/skywalkers_balloon_club/">
        <InstagramIcon />
      </A>
    </Aside>
  )
}

export default SocialMedia
