import React from "react"
import { ButtonPrimary } from "../ButtonPrimary/ButtonPrimary"
import styled from "styled-components"
import { Menu, MenuItem } from "@material-ui/core"
import LanguageIcon from "@material-ui/icons/Language"
import { useIntl, changeLocale } from "gatsby-plugin-intl"

const StyledMenu = styled(Menu)`
  padding: 0;
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  visibility: visible;
  width: fit-content;
  background-color: transparent;
  box-shadow: none;
  margin-top: -2px;

  &:hover {
    background-color: transparent;
  }

  .MuiButton-label {
    color: black;
  }

  .MuiSvgIcon-root {
    fill: black;
  }
`

const LanguageSwitcher = () => {
  const intl = useIntl()
  const currentLanguage = intl.locale
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? "language-popover" : undefined

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClick = language => {
    changeLocale(language)
    handleClose()
  }

  return (
    <>
      <StyledButtonPrimary
        startIcon={<LanguageIcon color="black" />}
        onClick={handleOpen}
      >
        {currentLanguage.toUpperCase()}
      </StyledButtonPrimary>

      <StyledMenu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => handleClick("pl")}>🇵🇱 PL</MenuItem>
        <MenuItem onClick={() => handleClick("en")}>🇬🇧 EN</MenuItem>
      </StyledMenu>
    </>
  )
}

export default LanguageSwitcher
