import React from "react"
import styled from "styled-components"
import Logo from "../components/Logo/Logo"
import { Typography } from "@material-ui/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import smoothscroll from "smoothscroll-polyfill"
import SocialMedia from "../components/SocialMedia/SocialMedia"
import { useIntl } from "gatsby-plugin-intl"

const Wrapper = styled.footer`
  background-color: #fff;
  border-top: 1px solid #2d2d2d;
`
const InnerWrapper = styled.div`
  display: flex;
  max-width: 1500px;
  padding: 40px;
  flex-wrap: wrap;
  margin: 0 auto;
`

const StyledTypography = styled(Typography)`
  font-size: 12px;
  color: gray;
  display: ${({ tag }) => (tag ? "inline-block" : "block")};
  margin-right: ${({ tag }) => (tag ? "5px" : 0)};
  text-transform: ${({ tag }) => (tag ? "uppercase" : "none")};
`
const StyledTitle = styled(Typography)`
  font-size: 16px;
`

const Column = styled.div``

const StyledAniLink = styled(AniLink)`
  font-size: 12px;
  color: gray;

  display: block;
  margin: 10px;
`

const Downbar = styled.p`
  width: 100%;
  font-size: 12px;
  color: gray;
  a {
    color: gray;
  }
`
const Footer = () => {
  const { locale, formatMessage } = useIntl()
  const handleNavigation = e => {
    const navRef = document.querySelector("header")
    e.preventDefault()
    const sectionName = e.target.id
    const navHeight = navRef.getBoundingClientRect().height
    const bodyRect = document.body.getBoundingClientRect().top
    smoothscroll.polyfill()
    switch (sectionName) {
      case "home":
        if (window.location.pathname === `/${locale}/`) {
          window.scrollTo({ top: 0, behavior: "smooth" })
        } else return
        break
      case "about":
        if (window.location.pathname !== `/${locale}/`) {
          setTimeout(() => {
            const sectionAbout = document.querySelector(`.about-us-description`)
            const positionAbout = sectionAbout.getBoundingClientRect().top
            const elementAboutPosition = positionAbout - bodyRect - navHeight
            window.scrollTo({ top: 0 })
            window.scrollTo({ top: elementAboutPosition })
          }, 1500)
        } else {
          const sectionAbout = document.querySelector(`.about-us-description`)
          const positionAbout = sectionAbout.getBoundingClientRect().top
          const elementAboutPosition = positionAbout - bodyRect - navHeight
          window.scrollTo({ top: 0 })
          window.scrollTo({ top: elementAboutPosition })
        }

        break
      default:
        break
    }
  }
  return (
    <Wrapper>
      <InnerWrapper>
        <Column
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "40px 0 0",
            marginRight: "30px",
          }}
        >
          <Logo />
          <div style={{ marginTop: "15px" }}>
            <StyledTypography variant="subtitle2" component="p">
              Skywalkers Balloon Club
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p">
              Tel:{" "}
              <a
                href="tel:+48697303011"
                style={{ whiteSpace: "nowrap", color: "gray" }}
              >
                +48 697-303-011
              </a>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p">
              E-mail: kontakt@skywalkers.pl
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p">
              {formatMessage({ id: "Bank account number" })}: 47 1050 1360 1000
              0090 9678 5622
            </StyledTypography>
            <SocialMedia vertical="row" component="footer" />
          </div>
        </Column>
        <Column
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "40px 0",
            marginRight: "30px",
            maxWidth: 240,
          }}
        >
          <StyledTitle variant="subtitle2" component="p">
            {formatMessage({ id: "Where do we fly" })}
          </StyledTitle>
          <div>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>
                  {formatMessage({ id: "Krakow-Czestochowa Region" })}
                </strong>
              </AniLink>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>{formatMessage({ id: "Silesia" })}</strong>
              </AniLink>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>{formatMessage({ id: "Warsaw" })}</strong>
              </AniLink>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>
                  {formatMessage({ id: "Masurian Lake District" })}
                </strong>
              </AniLink>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>{formatMessage({ id: "Tatra Mountains" })}</strong>
              </AniLink>
            </StyledTypography>
            <StyledTypography variant="subtitle2" component="p" tag="true">
              <AniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/where-we-fly`}
              >
                <strong>{formatMessage({ id: "Krakow" })}</strong>
              </AniLink>
            </StyledTypography>
          </div>
        </Column>
        <Column
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "40px 0",
          }}
        >
          <StyledTitle variant="subtitle2" component="p">
            {formatMessage({ id: "Navigation" })}
          </StyledTitle>

          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div style={{ margin: "15px 10px 0", minWidth: "150px" }}>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/`}
                onClick={handleNavigation}
                id="home"
                style={{ marginLeft: 0 }}
              >
                Home
              </StyledAniLink>
            </div>
            <div style={{ margin: "15px 10px 0", minWidth: "150px" }}>
              <StyledTypography
                variant="subtitle2"
                component="p"
                style={{ marginTop: "10px" }}
              >
                {formatMessage({ id: "Offer" })}
              </StyledTypography>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/offer/flights`}
                onClick={handleNavigation}
              >
                {formatMessage({ id: "Touristic flights" })}
              </StyledAniLink>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/offer/banners`}
              >
                {formatMessage({ id: "Advertising banners" })}
              </StyledAniLink>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/offer/skydiving`}
              >
                {formatMessage({ id: "Skydiving" })}
              </StyledAniLink>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/offer/selling`}
              >
                {formatMessage({ id: "Balloon sales" })}
              </StyledAniLink>
            </div>
            <div style={{ margin: "15px 10px 0", minWidth: "150px" }}>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/`}
                onClick={handleNavigation}
                id="about"
                style={{ marginLeft: 0 }}
              >
                {formatMessage({ id: "About us" })}
              </StyledAniLink>
            </div>
            <div style={{ margin: "15px 10px 0", minWidth: "150px" }}>
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/contact`}
                id="contact"
                style={{ marginLeft: 0 }}
              >
                {formatMessage({ id: "Contact" })}
              </StyledAniLink>
            </div>
          </div>
        </Column>
        <Downbar>
          © 2020 SKYWALKERS. All rights reserved. Designed & Develop by{" "}
          <a href="https://codeverse.pl">Codeverse</a>
        </Downbar>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Footer
